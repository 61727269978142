<template>
  <div>
     <el-carousel :autoplay='true' :interval="2000">
        <el-carousel-item v-for="item in list" :key="item">
          <img class="wh100p" :src="item" alt="">
        </el-carousel-item>
      </el-carousel>
  </div>
</template>

<script>
export default {
    props:['list','hei'],
    data(){
        return{ }
    }
}
</script>
<style scoped lang='less'>

.el-carousel {
  width: 100%;
  // height: 600px !important;
}
.wh100p{
  width: 100%;
  // height: 600px;
  object-fit: contain;
}
/deep/.el-carousel__item,/deep/.el-carousel__container{
    width: 100%;
    height: 600px !important;
  }
/deep/.el-carousel__button{
  width: 15px;
  height:15px;
  border-radius: 50%;
}
/deep/.el-carousel__arrow{
  width: 30px;
  height: 50px;
  border-radius: 0;
  background-color: rgba(31,45,61,.25);
  i{
    font-weight: 700;
    font-size: 15px;
  }
}


@media (max-width: 750px) {
  .el-carousel {
   width: 100%;
   height: 150px !important;
  }
  /deep/.el-carousel__item,/deep/.el-carousel__container{
    width: 100%;
    height: 150px !important;
  }
  .wh100p{
    width: 100%;
    height: 150px;
    overflow: hidden;
}
}

</style>