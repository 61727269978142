<template>
  <!--基础存放容器-->
  <div class="swiper-container2">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="item in list" :key="item">
        <img class='wh100p' :src="item" />
      </div>
    </div>
    <div class="swiper-pagination swiper-pagination-white"></div>
    <!-- <div class="swiper-button-prev swiper-button-white"></div>
    <div class="swiper-button-next swiper-button-white"></div> -->
  </div>
</template>


<script>
import Swiper from "swiper";
export default {
    data(){
        return{ 
          list: [
            require("../assets/img/banner (4).jpg"),
            require("../assets/img/banner (5).jpg"),
          ],
         }
    },
    mounted() {
      this.getSwiper();
    },
    methods: {
    getSwiper() {
        new Swiper(".swiper-container2", {
          loop: true,
          autoplay: 2000,
          effect : 'fade',
          fadeEffect: {
            crossFade: true,
          },
          pagination: ".swiper-pagination",
          // 用户中断轮播后续播
          autoplayDisableOnInteraction: false,
        });
      },
    },
}
</script>
<style scoped>
.wh100p{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-size: 100% 100%
}
@import "../../node_modules/swiper/dist/css/swiper.css";
.swiper-container2 {
  width: 470px;
  height: 270px;
}

.swiper-slide img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-size: 100% 100%
}
.swiper-button-prev,.swiper-button-next{
  width: 30px;
  height: 50px;
  border-radius: 0;
  background-color: rgba(31,45,61,.25);
}
</style>
