<template>
  <!--基础存放容器-->
  <div class="zizhi_ner">
    <div class="swiper-container1" id="swiper-container1">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          style="width: 320px"
          v-for="(item, index) in list"
          :key="index"
          @click="godetail(index+1)"
        >
          <img class="wh100p" :src="item.url" alt="" />
          <p class="swiper-pp">{{ item.title }}</p>
        </div>
      </div>
      <div class="swiper-button-prev swiper-button-white"></div>
      <div class="swiper-button-next swiper-button-white"></div>
    </div>
  </div>
</template>

  
<script>
import Swiper from "swiper";
export default {
  props: ["list"],
  data() {
    return {};
  },
  mounted() {
    this.initSwiper();
  },
  methods: {
    godetail(val){
      this.setSessionItem('activeIndex',3);
      this.$router.push(`/solutionDetail?keyword=${val}`)
    },
    initSwiper() {
      var mySwiper = new Swiper(".swiper-container1", {
        loop: true,
        speed: 6000, //匀速时间
        autoplay: {
          delay: 0,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        spaceBetween: 12,
        slidesPerView: "auto",
        autoplayDisableOnInteraction: false,
        nextButton: ".swiper-button-next",
        prevButton: ".swiper-button-prev",
      });
      var comtainer = document.getElementById("swiper-container1");
      comtainer.onmouseenter = function () {
        mySwiper.stopAutoplay();
      };
      comtainer.onmouseleave = function () {
        mySwiper.startAutoplay();
      };
    },
  },
};
</script>
<style scoped lang='less'>
@import "../../node_modules/swiper/dist/css/swiper.css";
.swiper-container1 {
  width: 1000px !important;
  height: 260px;
  overflow: hidden;
  padding-bottom: 5px;
}
.swiper-slide {
  cursor: pointer;
  width: 320px !important;
  border: 2px solid #fff;
  border-radius: 10px;
  background-color: #fff;
  color: #000;
  object-fit: contain;
}
.swiper-slide img {
  width: 320px;
  // height: 256px;
  border-radius: 10px;
  background-size: 100% 100%;
}
.swiper-pp {
  margin: 0;
  margin-top: 12px;
}
.zizhi_ner {
  position: relative;
  /deep/.swiper-button-prev,
  /deep/.swiper-button-next {
    top: 145px;
    width: 30px;
    height: 50px;
    border-radius: 0;
    color: #000;
    background-size: 70% 45%;
  }
  /deep/.swiper-button-prev {
    left: -30px;
    z-index: 88;
  }
  /deep/.swiper-button-next {
    right: -30px;
  }
  .swiper-container-horizontal > .swiper-pagination-bullets {
    left: 100px !important;
    top: 230px;
  }
}

@media (max-width: 750px) {
  .swiper-container1 {
    width: 100%;
    height: 150px;
    overflow: hidden;
  }
  .swiper-slide {
    width: 160px !important;
    border: 2px solid #fff;
    border-radius: 10px;
    background-color: #fff;
    color: #000;
  }
  .swiper-slide img {
    width: 160px;
    // height: 114px;
    border-radius: 10px;
    background-size: 100% 100%;
  }
  .swiper-pp{
    margin-top: 8px;
    font-size: 13px;
  }
}
</style>
