<template>
  <div>
    <div>
      <swiper :list="list"></swiper>
    </div>
    <!-- 公司介绍 -->
    <div class="company">
      <div class="left animate__animated animate__bounceInLeft">
        <h2>公司介绍</h2>
        <p>
          广州禧闻信息技术有限公司位于广州市海珠区，是一家通过运用移动互联网、物联网和大数据技术进行创新融合，基于BIM技术在国内建筑行业、智慧医疗、智慧园区、指挥调度平台中的信息化应用致力于对信息管理系统开发、控制和人工智能系统研究，安全工程智能化监测的智能化系统集成高科技企业。
        </p>
      </div>
      <div class="left right animate__animated animate__bounceInRight">
        <swiper3></swiper3>
      </div>
    </div>
    <div class="solution">
      <div class="solution_title">
        <h2>行业解决方案</h2>
        <p>基于建筑智慧，实现数字转化，创建数智运维</p>
        <div class="solution_content">   
          <swiper4 :list="solution"></swiper4>
        </div>
      </div>
    </div>
    <div class="about"> 
      <h2 :class="{test:true,animate__animated:testShow,animate__bounceInLeft:testShow}"  ref="testref">关于禧闻</h2>
      <div class="about_title">
        <div class="top">
          <img src="../assets/img/resource (3).png" alt="">
          <h3 :class="{test:true,animate__animated:testShow,animate__fadeInLeftBig:testShow}"  ref="test2ref">新闻中心</h3>
          <p :class="{test:true,animate__animated:testShow,animate__fadeInLeftBig:testShow}"  ref="test3ref">了解更多相关行业咨询</p>
        </div>
        <div class="top">
          <img src="../assets/img/resource (1).png" alt="">
          <h3 :class="{test:true,animate__animated:testShow,animate__bounceInUp:testShow}"  ref="test4ref">公司记事</h3>
          <p :class="{test:true,animate__animated:testShow,animate__bounceInUp:testShow}"  ref="test5ref">公司"禧闻"乐见的趣事</p>
        </div>
        <div class="top">
          <img src="../assets/img/resource (2).png" alt="">
          <h3 :class="{test:true,animate__animated:testShow,animate__fadeInRightBig:testShow}"  ref="test6ref">公司资质</h3>
          <p :class="{test:true,animate__animated:testShow,animate__fadeInRightBig:testShow}"  ref="test7ref">强大的开发团队，占比公司人员70%</p>
        </div>

      </div>
      <div class="moth" :class="{test:true,animate__animated:testShow,animate__zoomIn:testShow}" ref="test8ref" @click="getAbout">
        更多
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import swiper from "../components/swiper.vue";
import swiper3 from "../components/swiper3.vue";
import swiper4 from "../components/swiper4.vue";
import footers from "../components/footers.vue";

export default {
  data() {
    return {
      list: [
        require("../assets/img/banner (1).jpg"),
        require("../assets/img/banner (2).jpg"),
        require("../assets/img/banner (3).jpg"),
      ],
      solution:[
        {
          url: require("../assets/img/jiedao.jpg"),
          title: "街道社会综合治理联动管理平台",
        },
        {
          url: require("../assets/img/gaoxinqu.jpg"),
          title: "高新区管委会招商企业服务智慧平台",
        },
        {
          url: require("../assets/img/yangjiang.jpg"),
          title: "危化品智慧管控平台解决方案",
        },
        {
          url: require("../assets/img/diancan.jpg"),
          title: "智慧校园零接触点餐平台",
        },
        {
          url: require("../assets/img/yuanqu.jpg"),
          title: "智慧园区生态运营平台",
        },
      ],
      // solution: [
      //   {
      //     url: require("../assets/img/solution (3).jpg"),
      //     title: "智慧建筑信息化建设",
      //   },
      //   {
      //     url: require("../assets/img/solution (2).jpg"),
      //     title: "智慧建筑信息化建设",
      //   },
      //   {
      //     url: require("../assets/img/solution (4).jpg"),
      //     title: "智慧建筑信息化建设",
      //   },
      //   {
      //     url: require("../assets/img/solution (5).jpg"),
      //     title: "智慧建筑信息化建设",
      //   },
      //   {
      //     url: require("../assets/img/solution (6).jpg"),
      //     title: "智慧建筑信息化建设",
      //   },
      //   {
      //     url: require("../assets/img/solution (7).jpg"),
      //     title: "智慧建筑信息化建设",
      //   },
      //   {
      //     url: require("../assets/img/solution (8).jpg"),
      //     title: "智慧建筑信息化建设",
      //   },
      //   {
      //     url: require("../assets/img/solution (9).jpg"),
      //     title: "智慧建筑信息化建设",
      //   },
      //   {
      //     url: require("../assets/img/solution (10).jpg"),
      //     title: "智慧建筑信息化建设",
      //   },
      //   {
      //     url: require("../assets/img/solution (11).jpg"),
      //     title: "智慧建筑信息化建设",
      //   },

      //   {
      //     url: require("../assets/img/solution (12).jpg"),
      //     title: "智慧建筑信息化建设",
      //   },
      //   {
      //     url: require("../assets/img/solution (13).jpg"),
      //     title: "智慧建筑信息化建设",
      //   },
      //   {
      //     url: require("../assets/img/solution (14).jpg"),
      //     title: "智慧建筑信息化建设",
      //   },
      //   {
      //     url: require("../assets/img/solution (15).jpg"),
      //     title: "智慧建筑信息化建设",
      //   },
      //   {
      //     url: require("../assets/img/solution (16).jpg"),
      //     title: "智慧建筑信息化建设",
      //   },
      //   {
      //     url: require("../assets/img/solution (17).jpg"),
      //     title: "智慧建筑信息化建设",
      //   },
      //   {
      //     url: require("../assets/img/solution (18).jpg"),
      //     title: "智慧建筑信息化建设",
      //   },
      //   {
      //     url: require("../assets/img/solution (19).jpg"),
      //     title: "智慧建筑信息化建设",
      //   },
      // ],
      scrollTop:'',
      testShow:false,
      testShow2:false,
      testShow3:false,
      testShow4:false,
      testShow5:false,
      testShow6:false,
      testShow7:false,
      testShow8:false,
     
    };
  },
  components: {
    swiper,
    swiper3,
    swiper4,
    footers
  },

  mounted() {
    // var dom = this.$refs['about'];
    // window.onscroll = function () {
        
    //     var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    //     console.log("滚动距离" + scrollTop);
    //     this.scrollTop = scrollTop;
        
    //   }
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.currentScroll = window.pageYOffset //表示当前滚动的位置
      // console.log(this.currentScroll);
 
      if (this.currentScroll >= this.$refs.testref.offsetTop - 680) { //当前滚动位置到达testref的时候，显示div（100作为调整用）
        this.testShow = true;
      }
      if (this.currentScroll >= this.$refs.test2ref.offsetTop - 680) {
        this.testShow2 = true;
      }
      if (this.currentScroll >= this.$refs.test7ref.offsetTop - 680) {
        this.testShow7 = true;
      }
      if (this.currentScroll >= this.$refs.test4ref.offsetTop - 680) {
        this.testShow4 = true;
      }
      if (this.currentScroll >= this.$refs.test5ref.offsetTop - 680) {
        this.testShow5 = true;
      }
      if (this.currentScroll >= this.$refs.test6ref.offsetTop - 680) {
        this.testShow6 = true;
      }
       if (this.currentScroll >= this.$refs.test8ref.offsetTop - 680) {
        this.testShow8 = true;
      }
      if (this.currentScroll >= this.$refs.test3ref.offsetTop - 680) {
        this.testShow3 = true;
        setTimeout(() => {
          this.testShow3 = false;
        }, 400);
      }
    },
    getAbout(){
      this.$router.push({
        path:"about"
      });

      sessionStorage.setItem('activeIndex','4');
      window.location.reload()

    }
  },
};
</script>

<style scoped lang='less'>
// @import "../../node_modules/swiper/dist/css/swiper.css";
.company {
  width: 1000px;
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
  .left {
    width: 47%;
    h2 {
      font-size: 30px;
      color: #111;
    }
    p {
      font-size: 18px;
      font-family: "微软雅黑";
      line-height: 30px;
      text-indent: 2em;
    }
  }
  .right {
    margin-top: 20px;
  }
}
.solution {
  // width: 100%;
  height: 520px;
  background: url("../assets/img/solution (1).jpg");
  background-size: 100%;
  margin-top: 50px;
  color: #fff;
  text-align: center;
  .solution_title {
    width: 1000px;
    height: 100%;
    border: 1px solid transparent;
    margin: 0 auto;
    h2 {
      font-size: 30px;
      margin-bottom: 40px;
      // margin: 35px 0 40px;
    }
    p {
      font-size: 20px;
    }
  }
  .solution_content {
    height: 300px;
    margin-top: 30px;
  }
}
.wh100p {
  width: 100%;
  height: 254px;
  background-size: 100% 100%;
}
/deep/.el-carousel__button {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: none;
}
/deep/.el-carousel__arrow {
  width: 30px;
  height: 50px;
  border-radius: 0;
  background-color: rgba(31, 45, 61, 0.25);
  i {
    font-weight: 700;
    font-size: 15px;
  }
}


p {
  margin: 0;
}

.about{
  width: 1000px;
  margin: 30px auto;
  text-align: center;
  h2{
    font-size: 30px;
    margin: 50px;
  }
  .about_title{
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
    box-sizing: border-box;

    .top{
      padding: 10px 0;
      width: 30%;
      border-radius: 10px;
      cursor: pointer;
      img{
        width: 150px;
        height: 150px;
      }
      h3{
        font-size: 18px;
        color: #333;
      }
      p{
        font-size: 16px;
        color: #666;
      }
    }
    .top:hover{
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
  }
  }
  .moth{
    border: 1px solid #2263b9;
    width: 240px;
    height: 38px;
    line-height: 38px;
    margin: 40px auto;
    border-radius: 30px;
    color: #2263b9;
    cursor: pointer;
  }
  .moth:hover{
    color: #fff;
    border: 1px solid #2263b9;
    background-color: #2263b9;
    
  }
}

@media (max-width: 750px) {
  .company {
    display: block;
    padding:0 10px;
    box-sizing: border-box;
    width: 100%;
    .left{
      width: 100%;
      h2{
        font-size: 18px;
        text-align: center;
      }
      p{
        font-size: 14px;
        color: #666;
        line-height: 30px;
      }
    }
    .right{
      display: none;
    }
  }
  .solution{
    width: 100%;
    height: 260px;
    .solution_title{
      width: 100%;
      h2{
        font-size: 18px;
        margin-bottom: 10px;
        // color: #333;
      }
      p{
        font-size: 12px;
        // color: #666;
      }
    }
    .solution_content {
      height: 150px;
    }
  }
  .wh100p {
    width: 100%;
    height: 114px;
    background-size: 100% 100%;
  }
  .about{
    width: 100%;
    // border: 1px solid #000;
    h2{
      font-size: 18px;
      margin: 10px;
    }
    .about_title{
      display: block;
      .top{
        width: 100%;
        margin-bottom: 15px;
        img{
          width: 100px;
          height: 100px;
        }
      }
    }
    .moth{
      display: none;
    }
  }
}

</style>